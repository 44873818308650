// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js or *_controller.ts.
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import { Toggle, Dropdown } from "tailwindcss-stimulus-components"

let application = Application.start()
const context = require.context("controllers", true, /_controller\.(js|ts)$/)

application.load(definitionsFromContext(context))
application.consumer = consumer
StimulusReflex.initialize(application, { consumer })
StimulusReflex.debug = process.env.RAILS_ENV === 'development'

application.register('toggle', Toggle)
application.register('dropdown', Dropdown)

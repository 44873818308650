import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["sortButton", "modal"]

  submit(event) {
    this.element.requestSubmit();
  }

  sort(event) {
    if (!this.hasSortButtonTarget) {
      return;
    }

    this.sortButtonTargets.forEach(btn => btn.classList.remove('active'));
    event.target.classList.add('active');
    let sortInput = this.element.querySelector('#order');
    sortInput.value = event.target.dataset.order;
    this.submit(event);
  }

  submitAndClose() {
    this.element.querySelector('form').requestSubmit();
    this.modalController.close();
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(this.modalTarget, 'modal');
  }
}

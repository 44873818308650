import Controller from './application_controller'

const HOVER_CLASS = 'bg-gray-200';

export default class extends Controller {
  /**
   * Handle drop event
   * 
   * @param event 
   */
  drop(event) {
    this.removeHightlight(event);
    const source = JSON.parse(event.dataTransfer.getData("text/plain"));
    const dropTarget = this.getTargetRow(event);
    event.preventDefault();
    if (source.id === dropTarget.dataset.id) {
      return;
    }
    
    let slaves = [source.id]
    Array.from(document.querySelectorAll('input[name="slaves[]"]:checked'))
         .forEach(inp => slaves.push(inp.dataset.id));
    
    this.stimulate(this.element.dataset.reflexToCall, dropTarget.dataset.id, slaves);
  }

  /**
   * Handle dragstart event
   * 
   * @param event 
   */
  dragstart(event) {
    const target = this.getTargetRow(event);
    const id = target.getAttribute("data-id");
    event.dataTransfer.setData("text/plain", JSON.stringify({id}));
    event.dataTransfer.effectAllowed = "move";
  }

  /**
   * Handle dragover event
   * 
   * @param event 
   */
  dragover(event) {
    let row = this.getTargetRow(event);
    row.classList.add(HOVER_CLASS);
    event.preventDefault();
    return true;
  }

  /**
   * Handle dragend event
   * 
   * @param event 
   */
  dragend(event) {
    event.srcElement.setAttribute('draggable', false);
  }

  /**
   * Handle dragenter event
   * 
   * @param event 
   */
  dragenter(event) {
    event.preventDefault();
  }

  /**
   * Handle dragenter event
   * 
   * @param event 
   */
  dragleave(event) {
    this.removeHightlight(event);
  }

  /**
   * Make element draggable
   * 
   * @param {Event} event 
   */
   enableHandle(event) {
    let row = this.getTargetRow(event);
    row.setAttribute('draggable', true);
  }

  /**
   * Remove hightlight class for dragover element
   * 
   * @param {Event} event 
   */
  removeHightlight(event) {
    let row = this.getTargetRow(event);
    row.classList.remove(HOVER_CLASS);
    event.preventDefault();
  }

  /**
   * Get drag element
   * 
   * @param {Event} event 
   * @returns {Element|null}
   */
  getTargetRow(event) {
    return event.target.closest('.drag-item');
  }

  /**
   * @inherit
   */
  beforeReflex() {
    this.startSpinner();
  }

  /**
   * @inherit
   */
  afterReflex() {
    this.endSpinner();
  }
}

import { Controller } from "stimulus"
import StimulusReflex from "stimulus_reflex"

export default class extends Controller {
  static targets = ["form", "progress"]

  connect() {
    StimulusReflex.register(this)
  }

  beforeReflex (element, reflex, noop, reflexId) {
    this.progressController.show();
  }

  afterReflex (element, reflex, noop, reflexId) {
    this.progressController.hide();

    Chartkick.destroyAll();
    let chartOptions = {"library": {"scales": {"x": {"display": false }}}, "dataset":{"borderWidth":0,"backgroundColor":"#f78b00"}};
    
    Array.from(this.charts).forEach((chartElement) => {
      let chartId = chartElement.dataset.chartId;
      let data = JSON.parse(chartElement.dataset['chartEntries']);
      new Chartkick["ColumnChart"](chartId, data, chartOptions);
    })
  }


  submit(event) {
    event.preventDefault();
    this.stimulate(this.formTarget.dataset.reflexToCall, this.formTarget);
  }

  get progressController() {
    return this.application.getControllerForElementAndIdentifier(this.progressTarget, 'progress')
  }

  get charts() {
    return document.getElementsByClassName('sections-chart')
  }
}

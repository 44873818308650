import Controller from "./application_controller"

export default class extends Controller {
  static targets = ["nextPageLink"]

  initialize() {
    this.observeNextPageLink()
  }

  async observeNextPageLink() {
    if (!this.hasNextPageLinkTarget) return

    await nextIntersection(this.nextPageLinkTarget)
    this.getNextPage();
  }

  async getNextPage() {
    let next = this.nextPageLinkTarget.querySelector('button');
    if (next) {
      this.stimulate(this.element.dataset.reflexToCall, next.dataset.page);
    }
  }

  beforeReflex() {
    if (this.spinnerController) {
      this.spinnerController.showSpinner();
    }
  }

  afterReflex() {
    if (this.spinnerController) {
      this.spinnerController.hideSpinner();
    }
    this.observeNextPageLink();
  }

  get spinnerController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById('spinner-wrapper'), 'spinner')
  }
}

const nextIntersection = (targetElement) => {
  return new Promise(resolve => {
    new IntersectionObserver(([element]) => {
      if (!element.isIntersecting) {
        return
      } else {
        resolve()
      }
    }).observe(targetElement)
  })
}

import Controller from "./application_controller"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  static targets = ["progress", "bar"]

  progressValue = 0;
  trickle = () => this.setValue(this.progressValue + Math.random() / 100);

  connect() {
    StimulusReflex.register(this)

    addEventListener('turbo:before-visit', () => this.show())
    addEventListener('turbo:load', () => this.hide())
  }

  show() {
    this.progressTarget.classList.remove('opacity-0');
    this.startTrickling();
  }

  hide() {
    this.setValue(1);
    this.stopTrickling();

    setTimeout(() => this.progressTarget.classList.add('opacity-0'), 250);
    setTimeout(() => this.setValue(0), 700);
  }

  startTrickling() {
    if (!this.trickleInterval) {
      this.trickleInterval = window.setInterval(this.trickle, 300);
    }
  }

  stopTrickling() {
    window.clearInterval(this.trickleInterval);
    delete this.trickleInterval;
  }

  setValue(newValue) {
    this.progressValue = newValue;
    if (this.progressValue > 1) { this.progressValue = 0; }
    this.refresh();
  }

  refresh() {
    requestAnimationFrame((() => {
      this.barTarget.style.width = `${10 + this.progressValue * 90}%`;
    }));
  }
}

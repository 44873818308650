import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "exportButton", "submitButton", "submitBlank", "extraForm"]

  connect() {
    super.connect();
    this.buttonDisabledClass = 'btn-disabled';
  }

  submit(event) {
    if (this.hasExtraFormTarget) {
      this.formTarget.querySelector('#start').value = this.extraFormTarget.querySelector('#start').value;
      this.formTarget.querySelector('#end').value = this.extraFormTarget.querySelector('#end').value;
      this.formTarget.querySelector('#order').value = this.extraFormTarget.querySelector('#order').value;
    }

    let format = event.target.dataset.format;
    this.formTarget.querySelector('#format').value = format;

    let button = (format === 'pdf') ? this.submitBlankTarget : this.submitButtonTarget;
    button.click();
  }

  changed(event) {
    let checkboxes = this.formTarget.querySelectorAll('input[type="checkbox"]');
    
    if (Array.from(checkboxes).some(inp => inp.checked == true)) {
      this.exportButtonTargets.forEach(btn => {
        btn.classList.remove(this.buttonDisabledClass);
        btn.disabled = false;
      });
    } else {
      this.exportButtonTargets.forEach(btn => {
        btn.classList.add(this.buttonDisabledClass);
        btn.disabled = true;
      });
    }
  }
}

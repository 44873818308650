import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ['chartData'];

  chartDataTargetConnected() {
    let chartOptions = this.element.dataset.options ? JSON.parse(this.element.dataset.options) : {"dataset":{curve: true, points: false}};
    let chartId = this.element.dataset.chartId;
    let data = JSON.parse(this.element.dataset.chartEntries);
    let chartType = this.element.dataset.chartkickType || 'LineChart';
    var oldChart = Chartkick.charts[chartId];
    if (oldChart) oldChart.destroy();

    new Chartkick[chartType](chartId, data, chartOptions);
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "overlay" ]

  loading() {
    this.overlayTarget.classList.remove("hidden");
    this.overlayTarget.classList.add("flex");
  }
}

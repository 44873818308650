import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["overlay", 'form', 'searchForm', "modal"]

  /**
   * Open unmerge modal
   * 
   * @param {Event} event 
   */
  unmergeOpen(event) {
    this.openModal(event, (modal) => {
      this.fillFormData(event);
      const modalContent = modal.querySelector('.confirm-content');
      modalContent.innerHTML = `All <b>"${event.target.dataset.title}"</b> data will be restored`;
    });
  }

  /**
   * Close modal and submit form
   * 
   * @param {Event} event 
   */
  mergeConfirm(event) {
    this.hideModal(event, (modal) => {
      this.loading();
      this.formTarget.requestSubmit();
    });
  }

  /**
   * Hide modal on cancel
   */
  modalCancel(event) {
    this.hideModal(event);
  }

  /**
   * Open modal
   * 
   * @param {Event} event
   */
  openModal(event, modalCb) {
    this.modalController.open(event, (modal) => modalCb(modal));
  }

  /**
   * Hide modal
   */
  hideModal(event, cb) {
    this.modalController.close(event, cb);
  }

  /**
   * Enable loader
   */
  loading() {
    this.overlayTarget.classList.remove("hidden");
    this.overlayTarget.classList.add("flex");
  }

  /**
   * Fill form data
   * 
   * @param {Event} event 
   */
  fillFormData(event) {
    let rehabilitateInput = this.formTarget.querySelector('#rehabilitated_id');
    let typeInput = this.formTarget.querySelector('#type');
    rehabilitateInput.value = event.target.dataset.id;
    typeInput.value = event.target.dataset.type;
    
    if (this.hasSearchFormTarget) {
      let searchInput = this.searchFormTarget.querySelector('input[name="query"]');
      let queryInput = this.formTarget.querySelector('#query');
      queryInput.value = searchInput ? searchInput.value : '';
    }
  }

  /**
   * Get modal controller
   * 
   * @returns {object}
   */
   get modalController() {
    return this.application.getControllerForElementAndIdentifier(this.modalTarget, 'modal');
  }
}

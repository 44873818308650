import Popover from "stimulus-popover"

export default class extends Popover {
  static targets = ["trigger"];

  /**
   * Override super.show() because of event.currentTarget.appendChild(fragment) for first time hover fails
   * 
   * @param {*} event 
   */
  async show (event) {
    let content = null;

    if (this.hasContentTarget) {
      content = this.contentTarget.innerHTML
    } else {
      this.toggleCursor('progress')
      content = await this.fetch()
    }
    this.toggleCursor('default')

    if (!content) return

    this.hidePopovers()

    const fragment = document.createRange().createContextualFragment(this.makePopover(content))
    let el = event.currentTarget || event.srcElement
    el.appendChild(fragment)
  }

  hidePopovers() {
    document.querySelectorAll('[data-popover-target="card"]').forEach(card => card.remove());
  }

  toggleCursor(cursor) {
    if (this.hasTriggerTarget) {
      this.triggerTarget.style.cursor = cursor 
    }
  }

  makePopover(content) {
    return `
    <div data-popover-target="card" class="absolute max-w-72 bg-white p-2 right-0 bottom-7 shadow-2xl text-right">
      <div style="overflow-wrap: break-word;">
        ${content}
      </div>
    </div>`;
  }
}

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "static", "dynamic" ]

  connect() {
    addEventListener('turbo:before-visit', () => this.showSlides());
    this.insertImages();
  }

  insertImages() {
    let images = require.context("../images/patents", false).keys().map(imagePath => {
      return imagePath.replace('./', '') 
    }).filter(image => image.indexOf('.svg') !== -1)

    let leftCount;
    let imagesSize = leftCount = images.length;
    
    for (let i = 0; i <= imagesSize; i++) {
      let image = images.splice([Math.floor(Math.random()*leftCount)], 1)[0];
      leftCount--;
      
      if (image) {
        this.dynamicTarget.insertAdjacentHTML('beforeend', `<span class="hidden"><img src="${require('../images/patents/' + image)}"></span>`);
      }
    }
  }

  showSlides() {
    const svgs = this.dynamicTarget.children
    let currentSvg = this.staticTarget

    this.dynamicTarget.classList.remove('hidden')

    setInterval(() => {
      if (currentSvg) {
        currentSvg.classList.add('hidden')
      }

      currentSvg = svgs[Math.floor(Math.random()*svgs.length)]
      currentSvg.classList.remove('hidden')
    }, 5000)
  } 
}

// import ApplicationController from './application_controller'
import { Modal } from "tailwindcss-stimulus-components"
import StimulusReflex from "stimulus_reflex"

export default class extends Modal {
  static values = {
    autoOpen: {type: Boolean, default: false},
  }

  connect() {
    super.connect();
    this.element[this.identifier] = this
    StimulusReflex.register(this)
    this.autopOpen();
  }

   /**
   * Manually open modal confirmation form
   * 
   * @param {string} elementId
   * @param {mixed} cb
   * 
   * @returns {bool}
   */
  open(event, cb) {
    super.open(event);

    if (typeof cb === 'function') {
      cb(this.containerTarget);
    }
  }

  autopOpen() {
    if (!this.hasAutoOpenValue || this.autoOpenValue != true) return;

    // Unhide the modal
    this.containerTarget.classList.remove(this.toggleClass);

    // Insert the background
    if (!this.data.get("disable-backdrop")) {
      document.body.insertAdjacentHTML('beforeend', this.backgroundHtml);
      this.background = document.querySelector(`#${this.backgroundId}`);
    }
  }

  /**
   * Hide modal
   * 
   * @param {string} elementId
   * @param {mixed} cb 
   * 
   * @returns {bool}
   */
  close(event, cb) {
    super.close(event);
    if (typeof cb === 'function') {
      cb(this.element);
    }
  }

  /**
   * Confirm drop end action
   */
  confirm(event) {
    super.close(event);
  }

  /**
   * Cancel drop end action
   */
  cancel(event) {
    super.close(event);
  }

  beforeReflex() {
    if (this.spinnerController) {
      this.spinnerController.showSpinner();
    }
  }

  afterReflex() {
    if (this.spinnerController) {
      this.spinnerController.hideSpinner();
    }
  }

  get spinnerController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById('spinner-wrapper'), 'spinner')
  }
}

import Controller from "./application_controller"

export default class extends Controller {
  static targets = ["content", "spinner"]

  beforeReflex (element, reflex, noop, reflexId) {
    this.showSpinner()
  }

  afterReflex (element, reflex, noop, reflexId) {
    this.hideSpinner()
  }

  showSpinner() {
    this.contentTarget.style.filter = 'blur(1px)';
    this.spinnerTarget.classList.remove('hidden')
  }

  hideSpinner() {
    this.contentTarget.style.filter = 'none';
    this.spinnerTarget.classList.add('hidden')
  }

  perform(event) {
    event.preventDefault()
    this.stimulate(event.target.dataset.reflexToCall, event.target)
  }
}

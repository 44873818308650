import Controller from "./application_controller"
import qs from 'qs';

export default class extends Controller {
  static targets = ["link"]
  static values = {
    key: { type: String, default: 'cpc' }
  }

  modify(event) {
    if (!this.hasLinkTarget || !this.linkTarget.href) return;
    
    let url_parts = this.linkTarget.href.split('?');
    let path = url_parts[0];
    let query = qs.parse(url_parts[1]);
    query[this.keyValue] = event.target.dataset.param;
    this.linkTarget.href = `${path}?${qs.stringify(query)}`;
  }
}

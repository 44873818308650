import { Tabs } from "tailwindcss-stimulus-components"

export default class extends Tabs {
  switchTab(event) {
    let visiblePanelIndex = this.panelTargets.findIndex(el => !el.classList.contains('hidden'))
    console.log(this.index, visiblePanelIndex);
    if (this.index !== visiblePanelIndex) {
      this.index = this.index;
    }
  }
}

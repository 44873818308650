import Controller from './application_controller'

export default class extends Controller {
  toggleCollapse(event) {
    let id = event.currentTarget.dataset.collapseId;

    event.currentTarget.classList.toggle('active');
    document.querySelectorAll('.collapse-' + id)
            .forEach(el => el.classList.toggle('hidden'));
  }

  activateUnmerge(event) {
    let masterId = event.target.dataset.masterId;
    if (document.querySelectorAll(`[data-master-id="${masterId}"]:checked`).length != 0) {
      document.getElementById(`unmerge-${masterId}`).classList.remove('hidden');
    } else {
      document.getElementById(`unmerge-${masterId}`).classList.add('hidden');
    }
  }

  unmergeModal(event) {
    let masterId = event.currentTarget.dataset.masterId;
    let slaves = Array.from(document.querySelectorAll(`[data-master-id="${masterId}"]:checked`))
                      .map(inp => inp.dataset.id);
    
    this.stimulate(event.currentTarget.dataset.reflexToCall, masterId, slaves);
  }
}
